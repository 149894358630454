.main__dashboard {
    width: 18%;
    padding: 10px;
    background-color: white;
    filter: drop-shadow(3px 3px 5px #f0f0f0);
    position: fixed;
    height: 100%;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: default;
}

.main__todo {
    width: 80%;
    padding-left: 10px;
    min-height: 97vh;
}

.main__container {
    display: flex;
    flex-direction: row;
    margin: 8px;
}

.dash__button {
    color: black;
    width: 95%;
    background-color: white;
    border-radius: 11px;
    /*border-color: #f0f0f0;*/
    /* border: 1px;*/
    border: 0px;
    border-style: solid;
    margin-bottom: 20px;
    /*filter:drop-shadow(3px 3px 5px #eeeded);*/
    height: 56px;
    font-weight: 500;
    text-align: left;
}

.main__logo {
    font-size: 35px;
    font-weight: 800;
    letter-spacing: -1.7px;
}

.dash__rest {
    position: relative;
    height: 73px;
    display: flex;
    margin-left: -10px;
    align-items: center;
    padding-left: 5px;
    justify-content: space-around;
}

.main__tapesignal {
    font-size: 14px;
    margin-top: -12px;
    margin-bottom: 20px;
}

.main__dashmargin {
    width: 21%;
}

.soundbird__logo {
    bottom: 4px;
    transform: translate(0px, -3px);
}

.birdsize {

    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-top: 15px;
}

.dash__gap {
    height: calc(100% - 561px);
}

.main__wave {
    width: 84%;
    height: 50px;
    position: fixed;
    transform: translate(18%, 100px);
    margin-left: -5vw;
    bottom: 0;
}

.playlists__controls {
    bottom: 0;
    height: 130px;
    width: 55vw;
    position: fixed;
    background-color: white;
    margin-left: 20vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.playlists__controlplayhead {
    width: 45vw;
    height: 10px;
    background-color: #eeeeee;
    border-radius: 20px;
    cursor: pointer;
}

.playlists__controlplaybutton {
    height: 42px;
    width: 42px;
    background-color: #eeeeee;
    border-radius: 50%;
    margin-top: 20px;
    cursor: pointer;
}

.playlists__playheaddot {
    height: 16px;
    width: 16px;
    background-color: white;
    margin-top: -3px;
    border-radius: 50%;
    cursor: pointer;
}

.dash__buttonicon {
    margin-right: 5px;
    transform: translate(0px, 3px);
}

.dash__buttoninfo {
    margin-left: -10px;
}

.dash__accountname {
    font-size: 13px;
    font-weight: 200;
    overflow: hidden;
    letter-spacing: -0.04px;
}

.dash__line {
    background-color: rgba(0, 0, 0, 0.03);
    height: 2px;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}

.dash__dot {
    background-color: #D9D9D9;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    margin-right: 2px;
}

.dash__dotcontainer {
    display: flex;
    flex-direction: row;
}

.dash__storageinfo {
    height: 70px;
}

.dash__storageh1 {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -0.3px;
    margin-left: 2px;
}

.dash__storageh2 {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.7px;
    margin-left: 2px;
    margin-top: -2px;

}


.dash__storagebar {
    width: 93%;
    height: 10px;
    background-color: #F6F6F6;
    border-radius: 2px;
    margin-top: 3px;
}

.dash__progress {
    width: 70%;
    height: 100%;
    background-color: #61EBB9;
    border-radius: 2px;
}

@media (max-width: 884px) {
    .main__dashboard {
        display: none;
    }

    .main__dashmargin {
        width: 0%;
    }

    .main__todo {
        width: 100%;
    }

}


.notification-badge {
    position: absolute;
    display: inline-block;
    background: red;
    color: white;
    border-radius: 50%;
    /* padding: 0 6px; */
    font-size: 10px;
    margin-left: 8px;
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 20px;
    /* padding-top: 5px; */
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: -18px;
    margin-left: 95px;
}


/* Mobile Menu Button & Overlay */
.mobile-menu-button {
    display: none;
}

.mobile-menu-overlay{
    display: none;
}

@media (max-width: 884px) {
    .mobile-menu-button {
        display: block;
        position: fixed;
        bottom: 30px;
        left: 30px;
        min-width: 60px;
        min-height: 60px;
        width: 60px;
        height: 60px;
        max-width: 60px;
    max-height: 60px;
        background-color: white;
        border: none;
        border-radius: 50%;
        box-shadow: 3px 3px 10px #03030330;
        z-index: 10000;
        cursor: pointer;
        padding: 0px
    }

    .mobile-menu-overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        z-index: 9999;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        
    }

    .mobile-menu-content {
        background-color: white;
        width: 80%;
        max-width: 300px;
        padding: 20px;
        box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.3);
        margin: 20px;
        border-radius: 10px;
        padding-block: 2px;
    }

    .mobile-menu-item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        border-bottom: 1px solid #f0f0f0;
        cursor: pointer;
        height: 50px;
    }

    .mobile-menu-item:last-child {
        border-bottom: none;
    }

    .mobile-menu-icon {
        margin-right: 10px;
        margin-top: -11px;
    }

    .mobile-menu-text {
        font-size: 16px;
        font-weight: 500;
    }
    .colab__lufs{
        display: none;
    }

}