.files__header {
    display: flex;
}

.files__searchcontainer {
    position: relative;
}

.files__search {
    flex: 1;
}





.files__searchbar {
    margin-top: 22px;
    outline: none;
    border: none;
    padding: 0 50px 0 20px;
    height: 50px;
    font-size: 17px;
    font-family: 'Avenir';
    letter-spacing: -0.3px;
    background-color: white;
    box-shadow: 3px 3px 10px #EAEAEA;
    border-radius: 12px;
    width: calc(100% - 150px);
    margin-left: 40px;
    margin-right: 50px;
    padding-left: 20px;
}

.files__searchicon {
    position: absolute;
    right: 15px;
    top: 50%;
    /* transform: translateY(-50%); */
    width: 20px;
    height: 20px;
    pointer-events: none;
    transform: translate(-270%, 0vw);
    cursor: pointer;
    z-index: 999;
}

.files__currentuser {
    margin-top: 22px;
    background-color: black;
    color: white;
    padding-right: 55px;
    padding-left: 55px;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    border-radius: 10px;
    cursor: default;
    margin-right: 40px;
    margin-left: -20px;
}

.files__secondaryheader {
    display: flex;
    justify-content: space-between;
    margin-right: 40px;
    margin-bottom: 0px;
}

.files__filetypeselector {
    display: flex;
    margin-left: -8px;
}

.files__filetypebutton {
    background-color: #F9F9F9;
    width: 9.5vw;
    height: 50px;
    border-radius: 100px;
    display: flex;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 17px;
    justify-content: center;
    align-items: center;
    letter-spacing: -0.3px;
    margin-left: 8px;
    cursor: pointer;
}

@media (min-width: 900px) {
    .files__filetypebuttonsmall {
        display: none;
    }
}


@media (max-width: 900px) {
    .files__filetypebutton {
        display: none;
    }

    .files__filetypebuttonsmall {
        display: flex;
        width: 150px;
        justify-content: space-around;
    }

    .files__smallbutton {
        width: 40px;
        height: 40px;
        cursor: pointer;

    }
}

.files__filetypebutton:hover {
    background-color: #e0e0e0;
}

.files__filetypebutton.selected {
    background-color: #EAEAEA;
}

.files__users {
    display: flex;
    justify-content: center;
    align-items: center;
}

.files__oneuser__container {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    background-color: white;

    /*box-shadow: 3px 3px 10px #EAEAEA;*/
    width: 25px;

    border-radius: 50px;
    color: white;
    margin-left: 3px;
}

.files__files {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Dynamically fit items */
    gap: 25px;
    /* Consistent 25px gap between items */
    margin-top: 30px;
    padding: 0 10px;
    /* Optional padding around the grid */
    margin-right: 20px;
}

.files__onefilecontainer {
    width: 100%;
    max-width: 250px;
    aspect-ratio: 1 / 1.3;
    /* Maintain a 200px:260px ratio */
    background-color: white;
    /*filter: drop-shadow(3px 3px 10px #EAEAEA);*/
    box-shadow: 3px 3px 10px #EAEAEA;
    border-radius: 11px;
    cursor: pointer;
    transition: transform 0.2s ease, width 0.2s ease, height 0.2s ease;
    position: relative;
}

.files__onefileimg {
    display: flex;
    justify-content: center;
    height: 72.15%;

}

.files__onefileimgsize {
    height: 93%;
    width: 92%;
    margin-left: 2.4%;
    object-fit: cover;
    margin-top: 5%;
    border-radius: 5%;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.files__onefiletype {
    position: absolute;
    transform: translate(-8px, -18px);
}

.files__onefiletypenondraggable {
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.files__onefilename {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -0.35%;
    margin-left: 7.5%;
    margin-right: 7.5%;
    line-height: 0.95;
    margin-top: -5px;
    margin-bottom: 5px;
    height: 20px;
    padding-top: 2px;
    max-width: 180px;
    display: inline-block;
    vertical-align: middle;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90%;
}

.files__onefilemembers {
    font-size: 15px;
    /* Scaled from 15px at 200px width */
    font-weight: bold;
    letter-spacing: -0.2%;
    /* Based on original -0.4px */
    color: #A3A3A3;
    margin-left: 7.5%;
    /* 15px at base width */
    margin-top: -5%;
    /* Scaled from -10px */
}

.files__onefiletime {
    font-size: 12px;
    font-weight: bold;
    letter-spacing: -0.2%;
    /* Scaled from -0.4px */
    color: #A3A3A3;
    text-align: right;
    margin-right: 7.5%;
    /* 15px at base width */
    margin-top: 1.5%;
    /* Scaled from 5px */
}

.files__container {
    margin-left: 20px;
    position: relative;
    height: 100vh;
}

.files__dragOverlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(200, 200, 200, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    margin: -100px;
}

.files__dragOverlayContent {
    font-size: 24px;
    color: #333;
    font-weight: bold;
}


/* Existing styles... */

/* Styles for the Add User button */
.files__adduserbutton {
    width: 45px;
    height: 45px;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 7px;
    border-radius: 100px;
    background-color: white;

    box-shadow: 3px 3px 10px #03030325;
}

.files__adduserbutton:hover {
    background-color: #ffffff;
}

/* Styles for the pop-up menu */
.files__addUserPopup {
    position: absolute;
    background-color: white;
    height: fit-content;
    border-radius: 25px;
    padding: 15px;
    z-index: 1000;
    filter: drop-shadow(3px 3px 10px #03030325);
    width: 231px;
}



.files__addUserInput {
    width: 197px;
    padding: 15px;
    margin-bottom: 5px;
    border: 2px solid #F2F2F2;
    border-radius: 10px;
    filter: drop-shadow(3px 3px 5px #03030311);
    margin-top: 5px;
}

.files__popupinfo {
    font-size: 14px;
    color: #b1b1b1;
    margin-left: 3px;
    margin-bottom: 3px;
    margin-bottom: 8px;
    text-align: center;
}

.files__addUserPopupButtons {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    gap: 7px;
}

.files__addUserPopupButtons button {
    margin-left: 2px;
    /* padding: 12px 0px; */
    border: none;
    border-radius: 12px;
    cursor: pointer;
    background-color: black;
    color: white;
    width: 100%;

}

.files__addUserPopupButtons button:hover {
    background-color: #141414;
}


.files__addmembersvg {
    transform: translate(1px, 1px);
}

@media (max-width: 900px) {
    .files__currentuser {
        display: none;
    }
}

.files__notesbuttonsize {

    margin-left: -11px;
    margin-top: -1px;
}

/* Share button */
.files__shareButton {
    position: absolute;
    bottom: 7.5%;
    right: 30px;
    background: none;
    border: none;
    cursor: pointer;
    background-color: #2e2e2e40;
    display: flex;
    justify-content: center;
    color: #ffffff;
    font-weight: 100;
    font-size: 16px;
    box-shadow: 0 2px 5px rgba(255, 255, 255, 0.2);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(5px);
    box-shadow: 3px 3px 10px #0303030f;
    padding-block: 14px;
}

/* Share menu */
.files__shareMenu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;

    border-radius: 5px;
    z-index: 999;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    font-size: 15px;
}

.files__shareMenuItem {
    padding: 10px 15px;
    cursor: pointer;
    position: relative;
    z-index: 100;
}

.files__shareMenuItem:hover {
    background-color: #f0f0f0;
}

/* Invite popup */
/* Invite popup */
.files__invitePopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    /* Optional: adds a semi-transparent background */
    backdrop-filter: blur(8px);
}

.files__invitePopupContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    background-color: white;
    padding: 50px;
    border-radius: 25px;
    box-shadow: 3px 3px 10px #03030325;
    gap: 20px;
}


.files__invitePopupContent h3 {
    margin-bottom: 15px;
}

.files__invitePopupContent input {
    width: 100%;
    padding: 10px;

    outline: none;
    border: none;
    background-color: #000000;
    border-radius: 10px;
    padding: 20px 15px;
    font-family: 'Avenir';
    font-size: 15px;
    color: white;
}

.files__invitePopupContent button {
    padding: 10px 20px;
    cursor: pointer;
}


.files__files.horizontal-layout {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 0px;
    overflow-x: auto;
    padding: 1rem 0;
}

.files__userpopup {
    transform: translate(0%, 45px);
    font-weight: 100;

}

.files__isupdated {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    right: 0;
    margin: 9px;
    opacity: 1;
    transform: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    color: white;
    font-weight: bold;
}

/* The badge itself (+X) */
.files__moremembers {
    position: relative;
    /* important if you want the popup to be absolutely positioned */
    background-color: #d6d6d6;
    /* or match your existing style */
    color: #fff;
    border-radius: 50%;
    width: 45px;
    /* if you want a circle */
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    margin: 0 5px;
    font-size: 14px;
    font-weight: 700;
}

/* The popup showing the extra emails */
.files__moremembers__popup {
    position: absolute;
    bottom: -63px;
    /* or top: 40px, or whichever offset you want */
    right: -250%;
    transform: translateX(50%);
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 8px 12px;
    border-radius: 6px;
    z-index: 9999;
    min-width: 120px;
    /* optional minimum width */
    display: flex;
    flex-direction: column;
    gap: 4px;
    /* space between lines */
    font-weight: 300;
}

.files__moremembers__row {
    font-size: 0.85rem;
    white-space: nowrap;
    /* avoid wrapping each email's text */
}

/* Optionally you can add an arrow or anything else if you want a tooltip style. */


.files__addToWorkspaceButton {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 99;
    background: #000;
    color: #fff;
    border: none;
    border-radius: 4px;
    width: 26px;
    height: 26px;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    cursor: pointer;
    opacity: 0.85;
}

.files__addToWorkspaceButton:hover {
    opacity: 1;
}


/* Invite History Overlay Modal */
.invite-history-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(8px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2000;
    /* Ensure it overlays all other content */
}

.invite-history-card {
    background: #fff;
    border-radius: 20px;
    padding: 30px;
    max-width: 600px;
    width: 90%;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
    position: relative;
}

.invite-history-card h2 {
    margin-top: 0;
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
}

.invite-history-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.files__searchoutsideTitle {
    font-size: 15px;
    margin-left: 25px;
}

@media (max-width: 500px) {
    .files__files {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    }

    .files__filetypeselector{
        width: 0px;
    }
    .files__filetypebuttonsmall{
        display: none;
    }

    .files__files.horizontal-layout{
        padding: 0px;
        width: 100vw;
        transform: translate(-45px, 0px);
    }
}

@media (max-width: 600px) {
    .files__header {
        flex-direction: column;
    }

    .files__search {
        margin-top: -20px;
    }

    .invites__uploadfilebutton{
        display: none;
    }

}