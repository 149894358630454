/* ---------- Pricing Section Demo Container ---------- */
.pricing-section-demo {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 5rem;
    transform: scale(0.9);
  }
  
  .pricing-demo-bg-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -10;
  }
  
  .pricing-demo-bg {
    height: 100%;
    width: 100%;
    background-image: 
      linear-gradient(to right, rgba(79,79,79,0.18) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(79,79,79,0.18) 1px, transparent 1px);
    background-size: 35px 35px;
    opacity: 0.3;
    -webkit-mask-image: radial-gradient(ellipse 80% 50% at 50% 0%, #000 70%, transparent 110%);
    mask-image: radial-gradient(ellipse 80% 50% at 50% 0%, #000 70%, transparent 110%);
  }
  
  /* ---------- Pricing Section ---------- */
  .pricing-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2.5rem;
    padding: 2.5rem 0;
  }
  
  /* Header */
  .pricing-section-header {
    text-align: center;
  }
  
  .pricing-section-header-title > * + * {
    margin-top: 1rem;
  }
  
  .pricing-section-title {
    font-size: 2.25rem;
    font-weight: 500;
  }
  
  @media (min-width: 768px) {
    .pricing-section-title {
      font-size: 3rem;
    }
  }
  
  .pricing-section-subtitle {
    color: #6b7280;
  }
  
  /* Tabs */
  .pricing-tab-container {
    margin: 0 auto;
    display: flex;
    width: fit-content;
    border-radius: 9999px;
    background-color: #f3f4f6;
    padding: 0.25rem;
  }
  
  .pricing-tab {
    border: none;
    background: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 1rem;
    color: #1f2937;
  }
  
  .pricing-tab.active {
    font-weight: bold;
    border-radius: 9999px;
    background-color: #ffffff;
  }
  
  .pricing-tab.discount {
    /* add extra discount styles if desired */
  }
  
  /* Grid */
  .pricing-grid {
    display: grid;
    width: 100%;
    max-width: 72rem;
    gap: 1.5rem;
  }
  
  @media (min-width: 640px) {
    .pricing-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (min-width: 1280px) {
    .pricing-grid {
      grid-template-columns: repeat(4, 1fr);
    }
  }
  
  /* ---------- Pricing Card ---------- */
  .pricing-card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    overflow: hidden;
    padding: 1.5rem;
    border: 1px solid #e5e7eb;
    border-radius: 0.5rem;
  }
  
  .pricing-card.highlighted {
    background-color: #1f2937;
    color: #f9fafb;
  }
  
  .pricing-card.default {
    background-color: #f9fafb;
    color: #1f2937;
  }
  
  .pricing-card.popular {
    box-shadow: 0 0 0 2px #3b82f6;
  }
  
  /* Title */
  .pricing-card-title {
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-size: 1.25rem;
    font-weight: 500;
    text-transform: capitalize;
  }
  
  .badge {
    margin-top: 0.25rem;
    z-index: 10;
  }
  
  /* Price */
  .pricing-card-price-container {
    position: relative;
    height: 3rem;
    font-weight: bold;
  }
  
  .pricing-price {
    font-size: 2.25rem;
    font-weight: bold;
  }
  
  .pricing-card-price-note {
    margin-top: -0.5rem;
    font-size: 0.75rem;
    color: #6b7280;
  }
  
  /* Features */
  .pricing-card-features {
    flex: 1;
  }
  
  .pricing-card-features-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .pricing-card-features-list li + li {
    margin-top: 0.5rem;
  }
  
  .pricing-card-feature {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.875rem;
    font-weight: 500;
  }
  
  .feature-highlighted {
    color: #f9fafb;
  }
  
  .feature-default {
    color: #6b7280;
  }
  
  /* Button */
  .pricing-card-button {
    width: 100%;
    padding: 0.75rem;
    font-size: 1rem;
    cursor: pointer;
    border: none;
    border-radius: 0.375rem;
    background-color: #3b82f6;
    color: #ffffff;
  }
  
  .pricing-card-button:hover {
    background-color: #2563eb;
  }
  
  /* Arrow */
  .pricing-card-arrow {
    margin-left: 0.5rem;
    height: 1rem;
    width: 1rem;
  }
  
  /* Background overlays */
  .pricing-card-highlight-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: 
      linear-gradient(to right, rgba(79,79,79,0.18) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(79,79,79,0.18) 1px, transparent 1px);
    background-size: 45px 45px;
    -webkit-mask-image: radial-gradient(ellipse 80% 50% at 50% 0%, #000 70%, transparent 110%);
    mask-image: radial-gradient(ellipse 80% 50% at 50% 0%, #000 70%, transparent 110%);
  }
  
  .pricing-card-popular-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(ellipse 80% 80% at 50% -20%, rgba(120,119,198,0.1), rgba(255,255,255,0));
  }
  

