.account_title {
    height: 80px;
    font-weight: 700;
    padding: 10px;
    font-size: 50px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
}

.account__header {
    height: 299px;
    background-color: white;
    width: 77vw;
    border-radius: 13px;
    filter: drop-shadow(3px 3px 5px #e8e7e7);
}

.account_pic {
    width: 150px;
    /*background-color: red;*/
    border-radius: 50%;
    height: 150px;
    margin-left: 5px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */

}

.account_pic2 {
    width: 150px;
    border-radius: 50%;
    height: 150px;
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.account__filechooser {
    position: absolute;
    outline: none;
    color: transparent
}

input::file-selector-button {
    font-weight: 400;
    color: rgb(140, 140, 140);
    padding: 1rem;
    padding-inline: 2rem;
    border: thin solid rgb(211, 211, 211);
    border-radius: 5px;
    background-color: transparent;
    filter: drop
}

.account__headercontent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-weight: 800;
    height: 100%;
    align-items: center;
}

.account__left {
    display: flex;
    margin-left: 30px;
    height: 150px;

}

.account__right {
    margin-right: 30px;
    height: 150px;
}

.account__info {
    margin-left: 18px;
    margin-top: 22px;
    justify-content: center;
    flex-direction: column;
}

.account__name {
    font-size: 42px;
    margin-bottom: -12px;
}

.account__email {
    font-size: 18px;
}

.account__projectinfo {
    margin-top: 22px;
    margin-bottom: -12px;
}

.account__settings {
    width: 100%;
}


.account__settings__container {
    display: flex;
    justify-content: space-between;
    font-weight: 800;
    font-size: 15px;
    margin-top: 28px;
    width: 100%;
}

.account__settings__title {
    font-size: 25px;
    margin-bottom: 20px;
}

.account__settings__left {}

.acccount__settings__button {
    width: 100%;
    height: 57px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(2px 2px 3px #efefef);
    margin-bottom: 15px;
    border-radius: 11px;
    cursor: pointer;
}

.acccount__settings__input {

    width: 96%;
    height: 70px;
    background-color: rgb(0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-bottom: 15px;
    border-radius: 11px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    padding: 0% 2%;
}


.acccount__settings__input input {
    /* background: none; */
    font-family: 'Inter', sans-serif;
    border: none;
    background-color: #000000;
    color: white;
    text-align: right;
    font-size: 16px;
    width: 500px;
}

.acccount__settings__input input:is(:-webkit-autofill, :autofill) {
    -webkit-text-fill-color: #ffffff;
    -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
    color: white;
  }
.account__settings__input input:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 30px #000000 inset !important;
    -webkit-text-fill-color: white !important;
}


.acccount__settings__input__left {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.account__settings__card {
    width: 20vw;
    height: 240px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(2px 2px 3px #efefef);
    margin-bottom: 15px;
    border-radius: 11px;
    cursor: pointer;
}

.account_pic {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-left: 5px;
    position: relative;
    overflow: hidden;
  }
  
  .account_pic2 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  /* Overlay on hover */
  .profile_overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;

  }
  .account_pic:hover .profile_overlay {
    opacity: 1;
  }
  
  /* Crop modal styles */
  .crop-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .crop-modal-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    position: relative;
    width: 400px;
    height: 400px;
    display: flex;
    flex-direction: column;

  }
  
  .crop-modal-controls {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    z-index: 99;
  }
  
  .acccount__settings__input__right{
    font-weight: 200;
  }

  /* Modal overlay */
.change-password-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(7px);
    z-index: 1000;
  }
  
  /* Modal content box */
  .change-password-modal .modal-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 35px;
    width: 90%;
    max-width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
    text-align: center;
  }
  
  /* Modal title */
  .change-password-modal h2 {
    margin-top: 0;
    margin-bottom: 5px;
    letter-spacing: -1px;
  }
  
  /* Error and success messages */
  .change-password-modal .error-message {
    color: red;
    margin-bottom: 10px;
  }
  
  .change-password-modal .success-message {
    color: green;
    margin-bottom: 10px;
  }
  
  /* Input styling */
  .change-password-modal input[type="password"] {
    width: 100%;
    padding: 18px 15px;
    margin-bottom: 15px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  /* Modal buttons container */
  .change-password-modal .modal-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  /* Button styling */
  .change-password-modal button {
    flex: 1;
    padding: 18px 15px;
    margin: 5px;
    border: none;
    border-radius: 15px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .change-password-modal button:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .change-password-modal input{
    width: 100%;
    padding: 18px 15px;
    margin-bottom: 15px;
    border: 1px solid #e3e3e3;
    border-radius: 20px;
    font-size: 16px;
    box-sizing: border-box;
  }
  