.notifications__container {
  width: 100%;
  height: 97vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications__card {
  width: 667px;
  height: 80vh;
  border-radius: 49px;
  box-shadow: rgb(235, 234, 234) 2px 2px 15px;
  display: flex;
  flex-direction: column;
}

.notifications__top {
  padding: 50px 60px;
  font-weight: bold;
  font-size: 32px;
}

.notifications__line {
  margin-left: 7.5%;
  width: 85%;
  height: 1px;
  background-color: #D9D9D9;
  margin-top: -25px;
}

/* Make the content container flex-grow and scrollable. 
     Replace horizontal margins with padding so that the right spacing stays consistent. */
.notifications__content {
  flex: 1;
  overflow-y: auto;
  padding: 20px 7.5%;

}

.onenotification__container {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
   min-height: 65px; 
  margin-inline: -10px;
  padding-inline: 10px;
  margin-bottom: 12px;
}

.onenotification__container:hover {
  background-color: #f8f8f8;
  border-radius: 20px;
}

.onenotification__piccontainer {
  display: flex;
  align-items: center;
}

.onenotification__pic {
  min-width: 42px;
  min-height: 42px;
  max-width: 42px;
  max-height: 42px;
  border-radius: 50%;
}

.onenotification__message {
  font-size: 15px;
  max-width: 73%;
}

.onenotification__timestamp {
  color: #adadad;
  font-size: 13px;
  right: 0;
  bottom: 0;
}

@media (max-width: 600px) {
  .notifications__container {
    height: 84vh;

  }

}