.invite__container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    backdrop-filter: blur(5px);
}

.invite__cardcontainer {
    margin-left: 30vw;
    margin-top: 25vh;
    width: 730px;
    padding: 70px 0px;
    background-color: white;
    box-shadow: 3px 3px 10px #03030325;
    border-radius: 35px;
    font-size: 35px;
    font-weight: 200;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    letter-spacing: -0.8px;
}

.invite__button{
    background-color: black;
    color: white;
    width: fit-content;
    padding: 20px 50px;
    font-size: 18px;
    letter-spacing: -0.5px;
}

.invite__textmain{
    margin-bottom: 20px;
}

.invite__textsub{
    margin-top: -20px;
    font-size: 25px;
    margin-bottom: 20px;
}

.invite__accountbuttoncontainer
{
    display: flex;
    gap: 10px;
}

/* Overlay and Card Container */
.invite-history-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .invite-history-card {
    background-color: #f9f9f9;
    border-radius: 15px;
    max-width: 650px;
    width: 90%;
    padding: 20px;
    position: relative;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    max-height: 70vh;
  }
  
  /* Close Button */
  .invite-history-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 24px;
    cursor: pointer;
    width: 10px;
    /* padding: 20px; */
    transform: translate(-15px, 3px);
    padding: 0px;
  }
  
  /* List Styles */
  .invite-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .invite-item {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin: 15px 0;
    padding: 15px 0;
    overflow: hidden;
  }
  
  /* Top Row: Status, Token, Trash Can */
  .invitehistory__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px; /* 20px from container */
    margin-bottom: 10px;
  }
  
  .invitehistory__status {
    flex: 0 0 auto;
    font-size: 14px;
    text-transform: capitalize;
  }
  
  .invitehistory__link {
    flex: 0 0 250px;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    font-size: 15px;
    text-overflow: ellipsis;
    background-color: #000;
    color: #fff;
    padding: 5px 10px;
    border-radius: 10px;
  }
  
  .invitehistory__trashcan {
    flex: 0 0 auto;
  }
  
  .invitehistory__trashcan img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  
  /* Bottom Details: Two Columns */
  .invitehistory__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px 20px;
    padding: 0 20px;
    font-size: 16px;
    color: #333;
  }
  
  .detail-left, .detail-right {
    display: flex;
    align-items: center;
  }
  
  .detail-left span, .detail-right span {
    margin-left: 5px;
    font-weight: 500;
  }
  