.projects__title{
    height: 80px;
    font-weight: 700;
    padding: 10px;
    font-size: 50px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: default;
    letter-spacing: -0.9px;
    max-width: 30vw;
    overflow: hidden;
    white-space: nowrap;

}

.projects__container{
    width:87%;
    margin-left: 20px;
    margin-right: 20px;
    display: grid;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-columns: auto auto auto;
    margin-bottom: 30px;
    grid-template-columns: 33% 33% 33%;
    cursor: default;
}

.projects__project{
    height: 250px;
    background-color: white;
    width: 100%;
    border-radius: 10px;
    /*filter: drop-shadow(3px 3px 5px #ebeaea);*/

    cursor: pointer;
    overflow: hidden;
}

.projects__project:hover{
    /*transform:translateY(-3px)*/
}

.project__title{
    width: 80%;
    align-items: center;
    font-weight: 700;
    padding: 20px;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    cursor: pointer;
    overflow-wrap: break-word;
}

.projects__plusbuttoncontainer{
    position: fixed;
    bottom: 0;
    right: 0;
    padding: 30px;
}

.projects__plusbutton{
    bottom: 30px;
    right: 30px;
    width: 60px;
    background-color: black;
    color: white;
    border: none;
    border-radius: 50%;
    font-size: 25px;
    cursor: pointer;
    z-index: 100;
    box-shadow: 3px 3px 10px #03030325;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project__form{
    /*height: 80%;
    width: 50%;
    position: absolute;
    margin-top: -300px;
    margin-left: 12.5%;
    background-color: white;
    border-radius: 10px;
    filter: drop-shadow(3px 3px 5px #f0f0f0)*/
}

.projects__maincontainer{

    width: 100%;
}

.overlay__button{
    position: absolute;
    margin-top: -50px;
}

.project__xbutton{
    position: absolute;

}

.project__xbutton:hover{
    transform:translateY(-3px)
}

.projects__topcontainer{
    display: flex;
    justify-content: space-between;
}

.projects__searchbutton{
    margin-left: 35px;
    color: #dbd9d9;
    margin-left: 40vw;
    position: absolute;
    cursor: pointer;
}

.projects__searchbutton:hover{
    color:black;
}

@media (max-width: 600px) {
    .projects__title{
     
        max-width: 100vw;
    }
}