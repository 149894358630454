.pluginjuce__comments {
    position: relative;
    z-index: 1;
    min-height: 76vh;
}

.pluginjuce__title {
    font-size: 25px;
    font-weight: bold;
}

.pluginjuce__top {
    display: flex;
    height: 74px;
    justify-content: space-between;
    position: fixed;
    background-color: white;
    z-index: 999;
    padding: 5px;
    margin-top: -5px;
    transform: translate(0px, -80px);
    width: 100vw;
    box-shadow: 3px 3px 10px #03030315;

}

.pluginjuce__cover {
    width: 55px;
    height: 55px;
    border-radius: 7px;
}

.pluginjuce__topleft {
    display: flex;
    height: 70px;
    align-items: center;
    gap: 5px;
}

.pluginjuce__topright {
    display: flex;
    height: 70px;
    align-items: center;
    gap: 5px;
    margin-right: 20px;
}

.pluginjuce__time {

    color: #bfbfbf;
    font-size: 20px;
    margin-left: 20px;
    bottom: 25px;

}

.pluginjuce__time__current {

    color: #000000;
    font-size: 20px;
    margin-left: 5px;
    bottom: 25px;
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(7px);
    box-shadow: 3px 3px 10px #03030325;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    padding: 20px 35px;
    margin-right: 15px;
    -webkit-user-select: none;
    /* Safari */ 
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    cursor: default;
}

.pluginjuce__commentscontainer {
    margin-top: 80px;
    transform: translate(0px, 10px);
}

.pluginjuce__button {
    height: 64px;
    width: 64px;
    background-color: rgba(255, 255, 255, 0.95);
    backdrop-filter: blur(7px);
    box-shadow: 3px 3px 10px #03030325;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    cursor: pointer;
}

.juceplugin__files {
    width: 100vw;
    display: grid;
}

.juceplugin__selectortitle {
    font-size: 30px;
    font-weight: bold;
    letter-spacing: -0.7px;
    padding: 20px;
    margin-bottom: -30px;
}

.juceplugin__bottomcontainer {
    position: fixed;
    bottom: -10px;
    left: 0;
    /* margin-left: 21%; */
    display: flex;
    justify-content: left;
    align-items: center;
    padding-bottom: 20px;
    width: 79%;
    /* -webkit-backdrop-filter: blur(7px); */
    /*backdrop-filter: blur(7px);*/
    padding-top: 10px;
    padding-left: 10px;
    height: 85px;
    /* background-color: white; */
}

.pluginjuce__bottomright{
    position: absolute;
    left: 0;
    margin-left: calc(100vw - 80px);
}