.invites__headercontainer {
    position: relative;

    height: 82px;
    background-color: white;
    margin-top: -15px;
    border-radius: 10px;
    padding: -5px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: pointer;
    overflow: hidden;
    z-index: 1;
}

.invites__albumcover {
    width: 49px;
    height: 70%;
    border-radius: 5px;
    margin-left: 18px;
    cursor: pointer;

}


.invites__songdetails {
    margin-left: 14px;
    cursor: pointer;
}

.invites__songtitle {
    font-weight: 600;
    font-size: 18px;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: default;
    cursor: pointer;
    letter-spacing: -0.01px;
}

.invites__songmembers {
    font-size: 14px;
    color: #9a9a9a;

    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: pointer;
}

.invites__colabscontainer {
    margin-top: 50px;
}

.invites__img {
    border-radius: 5px;
    height: 49px;
    margin-top: 5px;
}

.invites__form {
    width: 669px;
    background-color: white;
    height: 350px;
    position: absolute;
    z-index: 999;
    border-radius: 20px;
    margin-top: -50px;
    box-shadow: 3px 3px 10px #03030325;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    position: fixed;
}

.invites__formblur {
    min-height: 100vh;
    width: 78%;
    z-index: 999;
    position: absolute;
    backdrop-filter: blur(7px);
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
}

.invites__formtop {
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.invites__formtitle {
    font-weight: 700;
    font-size: 40px;
    padding: 15px;
    cursor: default;
}

.invites__xbutton {
    padding: 21px;
    cursor: pointer;
    z-index: 99999;
}

.invites__imgsize {
    width: 30px;
}

.invites__inputcontainer {
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 50px;
}

.invites__inputinfo {
    font-size: 20px;
    font-weight: 200;
    margin-bottom: 10px;
}

.invites__input {
    outline: none;
    border: none;
    background-color: #ffffff;
    width: calc(100% - 14px);
    height: 55px;
    font-size: 40px;
    border-radius: 5px;
    font-family: "Avenir";
    padding: 7px;
    margin-bottom: 10px;
    font-weight: bold;
    letter-spacing: -1px;

}

.invites__input::placeholder {
    color: #B4B4B4;
}

.invites__combo {
    /*filter: drop-shadow(3px 3px 5px #f0f0f0);*/
}

.invites__dndzone {
    height: 100vh;
    width: 77vw;
    z-index: 999;
    position: fixed;

}

.invites__choicelabel {
    margin: 10px;
}

.invites__choicelabel:hover {
    color: blue;
}

#overlay {
    pointer-events: none;
}

.invites__grayoverlay {
    z-index: 999;
}

.oneinvite__rightcornertimestamp {
    position: absolute;
    margin-top: 45px;
    margin-right: 15px;
    font-size: 12px;
    color: #9a9a9a;
    right: 0;
    z-index: 5;
    -webkit-user-select: none;
    /* Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+/Edge */
    user-select: none;
    /* Standard */
    cursor: pointer;
}

.plusiconsize {
    width: 45px;

    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    margin-top: 15px;
}

.invites__formimg {
    width: 343px;
    height: 242px;
    background-color: #E1E1E1;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.invites__formmain {
    display: flex;
    margin-top: -49px;
    margin-left: 21px;

}

.invites__formmembersdisplay {}

.invites__formbottom {
    position: absolute;
    width: 630px;
    display: flex;
    justify-content: space-between;
    margin-inline: 21px;
    margin-top: 17px;
    transform: translate(0px, 267px);
}

.invites__submitbutton {
    width: 175px;
    height: 49px;
    background-color: black;
    color: white;
    font-size: 17px;
    letter-spacing: -0.5px;
    font-weight: 500;
    padding: 0px;
    cursor: pointer;
}

.file-upload-btn {
    display: inline-block;
    background-color: white;
    box-shadow: 3px 3px 10px #03030325;

    text-align: center;
    font-size: 16px;
    color: #333;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s, color 0.3s;
    width: 103px;
    height: 95px;
    border-radius: 6px;
    ;
    max-width: 300px;
}

.file-upload-btn:hover {
    background-color: #f0f0f0;
}

.file-upload-btn:active {
    background-color: #e0e0e0;
}

.file-upload-btn:focus {
    outline: none;
}

.file-upload-btn:after {
    content: attr(data-file-name);
}

#fileInput:focus+.file-upload-btn {
    border-color: #007BFF;
}

.invites__formsvg {
    position: absolute;
    pointer-events: none;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 3px 3px 10px #03030325;
    cursor: pointer;
}

.invites__formaddmember {
    box-shadow: 3px 3px 10px #03030325;
    margin-left: 22px;
    width: 305px;
    border-radius: 10px;
    padding: 10px 20px;
    background-color: white;
}

.invites__formaddmember__input {
    position: relative;
    outline: none;
    border: none;
    /* margin-left: 22px; */
    /* width: 305px; */
    height: 17px;
    border-radius: 8px;
    /* margin-top: -20px; */
    font-family: "Avenir";
    /* padding: 7px; */
    font-size: 18px;
    letter-spacing: -0.3px;

    /* display: flex
; */
    align-items: flex-start;
    line-height: 17px;
    /* padding: 20px; */
    background-color: white;
    resize: none;

}

.invites__formaddmember__input:focus {
    /*height: 350px;*/

    /* Keep text aligned at the top */


}

.invites__formaddmember__input::placeholder {
    color: #B4B4B4;
}


.invites__formonemember {
    height: 50px;
    width: 50px;
    background-color: #D9D9D9;
    border-radius: 50px;
}

.invites__formpreviewimg {
    height: 100%;
    width: 100%;
    border-radius: 16px;
    object-fit: cover;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.invites__friendslist {
    background-color: white;
    position: relative;
    border-radius: 10px;

    width: 305px;

    /* background-color: red; */
    height: fit-content;
    min-width: auto;

}

.invites__frienditem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
}

.invites__friendpic {
    background-color: #d9d9d9;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.invites__friendinfo {
    display: flex;
    align-items: center;
}

.invites__friendname {
    margin-left: 10px;
    font-size: 20px;
}

.invites__addfriendbutton {
    right: 0;
}

.invites__addemailitem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.invites__emailname {
    margin-left: 10px;
    font-size: 20px;
}

.invites__emailinfo {
    display: flex;
    align-items: center;
}

.invites__newprojectcontainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
}

.invites__newprojecttext {
    font-size: 20px;
    margin-top: 10px;
}

/* Spinner Overlay */
.loading-container {
    position: absolute;
    top: 0;
    /* left: 0; */
    width: 81%;
    height: 100%;
    z-index: 9999;
    background: rgb(255 255 255 / 35%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -10px;
}

/* The spinner (a ring that spins) */
.loading-spinner {
    width: 48px;
    height: 48px;
    border: 6px solid #ffffff;
    /* background ring */
    border-top: 6px solid #000000;
    /* color of spinning edge */
    border-radius: 50%;
}

.oneinvite__isupdated {
    position: absolute;
    right: 0;
    transform: translate(8px, -65px);
    background-color: red;
    width: 7px;
    height: 7px;
    border-radius: 50%;
}

.invites__uploadfilebutton:hover {
    background-color: blue;
}

.invites__nofilesmessage {
    height: 100%;
    background-color: white;
    /* filter: drop-shadow(3px 3px 10px #EAEAEA); */
    box-shadow: 3px 3px 10px #EAEAEA;
    width: 214%;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 300px;
}

.invites__nofilesmessage__bold {

    font-weight: bold;
    letter-spacing: -1px;
    font-size: 34px;
}

.invites__nofilesmessage__regular {

    width: 80%;
    margin-top: -5px;
    letter-spacing: -1px;
    font-size: 24px;
    text-align: center;
}



.oneinvite__productionstatus {
    position: absolute;
    margin-top: 0px;
    margin-right: 15px;
    font-size: 12px;
    color: #9a9a9a;
    right: 0;
    z-index: 5;
}

.productionstatus__flexbox {
    position: absolute;
    margin-top: 20px;
    display: flex;
    margin-right: 115px;
    font-size: 17px;
    color: #9a9a9a;
    right: 0;
    z-index: 5;
    justify-content: right;
    flex-direction: row;
    gap: 28px;
}

.productionstatus__container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    width: 34px;
    cursor: pointer;
}

.productionstatus__check {
    width: 20px;
    height: 20px;
    background-color: #F4F4F4;

    border-radius: 50%;
}

.productionstatus__check__checked {}

.productionstatus__name {
    font-size: 12px;
    color: #9a9a9a;
}

@media (max-width: 900px) {
    .productionstatus__flexbox {
      display: none;
    }
    .productionstatus__mobile {
      position: absolute;
      margin-top: 20px;
      margin-right: 115px;
      font-size: 17px;
      color: #9a9a9a;
      right: 0;
      z-index: 5;
    }
    .productionstatus__mobile button {
      background: none;
      border: none;
      font-size: 17px;
      color: #9a9a9a;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
    .productionstatus__mobile .checkedMark {
      margin-left: 5px;
      color: green; /* Adjust the color as needed for your production-ready design */
    }
  }
  

  .onefile__changeinput{
    font-family: 'Avenir';
    border: none;
    background-color: #f9f9f9;
    border-radius: 10px;
    font-size: 17px;
    font-weight: bold;
  }

